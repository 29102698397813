<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:500px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Menu</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid">

          <div class="field grid">
            <label for="name" :class="{'p-error':nameError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">{{ $t('forms.name') }}</label>
            <div class="col">
              <InputText id="name" type="text"
                v-model="name" 
                :class="{'p-invalid': nameError}"
                :disabled="isLoading"
              />
              <small v-if="nameError" class="p-error">{{nameError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleName" :class="{'p-error': cycleNameError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Cycle Name</label>
            <div class="col">
              <InputText id="cycleName" type="text"
                v-model="cycleName"
                :class="{'p-invalid': cycleNameError}"
                :disabled="isLoading"
                />
              <small v-if="cycleNameError" class="p-error">{{cycleNameError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleStartDate" :class="{'p-error': cycleStartDateError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">{{ $t('menus.cycleStartDate') }}</label>
            <div class="col">
              <Calendar id="cycleStartDate" dateFormat="yy-mm-dd" v-model="cycleStartDate"
                :class="{'p-invalid': cycleStartDateError}" :disabled="isLoading" />
              <small v-if="cycleStartDateError" class="p-error">{{cycleStartDateError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleEntryEndDate" :class="{'p-error': cycleEntryEndDateError, 'col-fixed justify-content-end': true}" style="width:150px">{{ $t('menus.cycleEntryEndDate') }}</label>
            <div class="col">
              <Calendar id="cycleEntryEndDate" dateFormat="yy-mm-dd" v-model="cycleEntryEndDate"
                :class="{'p-invalid': cycleEntryEndDateError}" :disabled="isLoading" />
              <small v-if="cycleEntryEndDateError" class="p-error">{{cycleEntryEndDateError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleEndDate" :class="{'p-error': cycleEndDateError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Cycle End date</label>
            <div class="col">
              <Calendar id="cycleEndDate" dateFormat="yy-mm-dd" v-model="cycleEndDate" 
                :class="{'p-invalid': cycleEndDateError}" :disabled="isLoading" />
              <small v-if="cycleEndDateError" class="p-error">{{cycleEndDateError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="creationMode" class="font-bold col-fixed justify-content-end" style="width:150px">Creation mode</label>
            <div class="col">
              <SelectButton id="creationMode" class="p-primary-button" v-model="creationMode" :options="creationModes" optionLabel="label" optionDisabled="disabled" />
            </div>
          </div>

          <div class="field grid" v-if="duplicateMode">
            <label for="menuToDuplicate" class="font-bold col-fixed justify-content-end" style="width:150px">Menu to duplicate</label>
            <div class="col">
              <AutoCompleteSearch id="menuToDuplicate" v-model="menuToDuplicate"
                itemValue="id" field="name" :suggestions="listingMenus" :suggestionsTotal="listingMenusTotal"
                dropdown dropdownIcon="search"
                @complete="searchMenu($event)"
                :class="{'p-invalid': menuToDuplicateError}" :disabled="isLoading" />
              <small v-if="menuToDuplicateError" class="p-error">{{menuToDuplicateError}}</small>
            </div>
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.submit')" icon="pi pi-check" :loading="isLoading" @click="create"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import InputText from "primevue/inputtext"
// import InputMask from "primevue/inputmask"
import Calendar from "primevue/calendar"
import Message from "primevue/message"
import SelectButton from "primevue/selectbutton"
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'

import { required, requiredUnless, between, minValue, maxValue } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

const MANUAL_MODE = { label: "Manual", disabled: false }
const DUPLICATE_MODE = { label: "Duplicate", disabled: true }

export default {
  name: 'MenuNew',
  setup: () => {
    return { 
      name: ref(''),
      cycleName: ref(''),
      cycleStartDate: ref(''),
      cycleEntryEndDate: ref(''),
      cycleEndDate: ref(''),
      menuToDuplicate: ref(null),
      creationMode: ref(MANUAL_MODE),
      creationModes: [MANUAL_MODE, DUPLICATE_MODE],
      listingMenus: ref([]),
      listingMenusTotal: ref(0),
      showIt: ref(false),
      isLoading: ref(false),
      errorCaught: ref(''),
      v$: useVuelidate(),
    }
  },
  props: ['modelValue'],

  components: { 
    InputText, Message, SelectButton, Calendar, AutoCompleteSearch
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.name = ''
      this.cycleName = ''
      this.cycleStartDate = ''
      this.cycleEntryEndDate = ''
      this.cycleEndDate = ''
      this.creationMode = MANUAL_MODE
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      const { name, cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate } = this

      this.$pxstream.portalmanager.createMenu({ 
        name, cycleName, 
        cycleStartDate: this.$pxstream.tools.toSimpleDate(cycleStartDate), 
        cycleEntryEndDate: this.$pxstream.tools.toSimpleDate(cycleEntryEndDate), 
        cycleEndDate: this.$pxstream.tools.toSimpleDate(cycleEndDate)
      })
      .then(({id}) => {
        this.showIt = false
        this.$router.push(`/portal-manager/menus/${id}`)
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    },
    searchMenu () {
      // TODO:
    }
  },

  computed: {
    nameError () {
      if (!this.v$.name.$dirty) return ''
      if (this.v$.name.required.$invalid) return 'Name is required.'
      return ''
    },
    cycleNameError () {
      if (!this.v$.cycleName.$dirty) return ''
      if (this.v$.cycleName.required.$invalid) return 'Cycle name is required'
      return ''
    },
    cycleStartDateError () {
      if (!this.v$.cycleStartDate.$dirty) return ''
      if (this.v$.cycleStartDate.required.$invalid) return 'Cycle start date is required'
      if (this.v$.cycleStartDate.maxValue.$invalid) return 'Cycle start date must be prior to end date'
      return ''
    },
    cycleEndDateError () {
      if (!this.v$.cycleEndDate.$dirty) return ''
      if (this.v$.cycleEndDate.required.$invalid) return 'Cycle end date is required'
      if (this.v$.cycleEndDate.minValue.$invalid) return 'Cycle end date must be after the start date'
      return ''
    },
    cycleEntryEndDateError () {
      if (!this.v$.cycleEntryEndDate.$dirty) return ''
      if (this.v$.cycleEntryEndDate.between.$invalid) return 'Cycle entry end date must be between start and end dates'
      return ''
    },
    menuToDuplicateError () {
      if (!this.v$.menuToDuplicate.$dirty) return ''
      if (this.v$.menuToDuplicate.required.$invalid) return 'Menu to duplicate is required'
      return ''
    },
    duplicateMode () {
      return this.creationMode.label === DUPLICATE_MODE.label
    },
    manualMode () {
      return this.creationMode.label === MANUAL_MODE.label
    }
  },

  validations () {
    return {
      name: { required },
      cycleName: { 
        required
      },
      cycleStartDate: {
        required,
        maxValue: maxValue(this.cycleEndDate)
      },
      cycleEntryEndDate: {
        between: between(this.cycleStartDate, this.cycleEndDate)
      },
      cycleEndDate: {
        required,
        minValue: minValue(this.cycleStartDate),
      },
      menuToDuplicate: { 
        required: requiredUnless('manualMode')
      }
    }
  },

  watch: {
    modelValue: function (val) {
      this.showIt = val
    },
    cycleStartDate: function (newValue, oldValue) {
      if (!oldValue || (this.cycleEndDate && (this.cycleEndDate < newValue))) {
        this.cycleEndDate = new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0)
      }
    }
  }
}
</script>
