<template>
  <div>
    <div v-if="doc">
      <MenuEditingCore :doc="doc" :flow="flow" @has-update="onUpdateChanged">
        <template #header="{ hasUpdate, hasError, hasLinksError, saveFlowBuild }">
          <div class="flex justify-content-end mb-2">
            <div>
              <Button :label="$t('actions.save')"
                icon="pi pi-check"
                iconPos="right"
                :disabled="!hasUpdate || isSaving || hasLinksError('*') || hasError"
                :loading="isSaving"
                @click="save(saveFlowBuild('*'))" />
            </div>
          </div>
        </template>
      </MenuEditingCore>
    </div>
    <div v-else>
      <div class="flex justify-content-center">
        <p class="text-4xl font-light text-center">
          {{ $t('menus.loadingMenu') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, provide, inject, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useFlowCruder, useFlowReset } from '@/compositions'
import Log from '@/services/logger'
import MenuEditingCore from './components/MenuEditingCore.vue'
import { LOGIN_PAGE } from '@/router'

const KFlow = "portalmanager:menu"

export default {
  name: 'MenuEditing',
  setup () {
    const pxstream = inject('pxstream')
    const isReloading = ref(false)
    const doc = ref(null)
    const hasUpdate = ref(false)
    const lastUpdate = ref(new Date(0))
    const { flowGet, ...flowCruderAPI } = useFlowCruder()
    const onUpdateChanged = (payload) => {
      hasUpdate.value = payload.hasUpdate
      lastUpdate.value = payload.at
    }
    const route = useRoute()

    const reloadDoc = async () => {
      isReloading.value = true
      useFlowReset()
      // Force EditingCore setup by removing dom
      doc.value = null
      let menuDoc
      if (!isUpdatingPreviousCycle.value) {
        const { data } = await flowGet(KFlow, route.params.id)
        menuDoc = data
      } else {
        const { data } = await pxstream.portalmanager.getPreviousCycleInOneMenu(route.params.id)
        menuDoc = data
      }
      
      doc.value = menuDoc
      isReloading.value = false
      // Reset hasUpdate flag
      onUpdateChanged({
        hasUpdate: false,
        at: new Date()
      })
    }

    const isUpdatingPreviousCycle = computed(() => {
      return route.name === 'PortalManagerMenusEditingPrevious'
    })

    provide('askReload', reloadDoc)

    return {
      isReloading,
      hasUpdate,
      lastUpdate,
      flow: KFlow,
      doc,
      reloadDoc,
      onUpdateChanged,
      flowGet,
      ...flowCruderAPI,
      isUpdatingPreviousCycle
    }
  },
  components: {
    MenuEditingCore
  },
  data: () => ({
    isNewItemCreating: false
  }),
  methods: {
    onAskReload () { this.reloadDoc() },
    async save (req) {
      Log('@@@ SAVE @@@')
      try {
        const { redirect } = await this.flowSave(req)
        this.$toast.add({severity: 'success', detail: 'Saved with success', summary: req.doc.name, life: 4000})
        if (redirect) {
          this.$router.push(`/portal-manager/menus/${redirect}`)
          return
        }

        this.reloadDoc()

      } catch (e) {
        Log.Error(e)
        this.$toast.add({severity: 'error', summary: 'Failed to save menu', detail: e.error || e.message, life: 4000})
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasUpdate) {
      return next()
    } else if (to.name === LOGIN_PAGE && to.query.redirect) {
      return next()
    }
    this.$confirm.require({
      header: 'You have unsaved changes!',
      message: 'Do you really want to leave?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => { next() },
      reject: () => { next(false) }
    })
  },
  async mounted () {

    useFlowReset()

    const docId = this.$route.params.id
    let title = `Menu ${docId}`
    let owner = '-- No Onwer--'

    try {
      let menuDoc
      if (!this.isUpdatingPreviousCycle) {
        const { data } = await this.flowGet(KFlow, docId)
        menuDoc = data
      } else {
        const { data } = await this.$pxstream.portalmanager.getPreviousCycleInOneMenu(docId)
        menuDoc = data
      }
      this.doc = menuDoc
      title = this.doc.name
      owner = this.doc.owner?.username || owner
      if (owner) owner = `By ${owner}`
    } catch (err) {
      this.$toast.add({severity: 'error', summary: 'Failed to get menu', detail: err.toString(), life: 4000})
      setTimeout(() => this.$router.push('/portal-manager/menus', 4000))
    }

    this.$breadcrumb.setCurrent(title, [
      {label: 'Portal Manager', disabled: true},
      {label: 'Menus', disabled: false, to: '/portal-manager/menus'},
      {label: title, disabled: true}
    ], {caption: owner, badge: docId === 'new' ? 'Draft' : ''})
  },
}
</script>
